
/**
 * Setting for the camera and the  measure section
 * settings to keep track of the input and output for the camera and measure section
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { ActionMessage } from "@/model/Messages/statusMessage";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import confirmModal from "@/mixins/confirmModal/confirmModal";
import Section from "@/model/ModelSection";
import { measureAndCameraSettings, SingleDeviceState } from "@/model/ModelSectionState";

@Component({
  name: "CameraAndMeasureSettings",
  computed: {
    ...mapGetters(["isUserAdmin", "getUserAccess"]),
    ...mapGetters("projects", {
      currentSectionState: "GetCurrentSectionState"
    })
  },
  methods: {
    ...mapActions("projects", {
      SET_MEASURE_CAMERA_SETTINGS: "SetMeasureAndCameraSectionSettings"
    })
  }
})
export default class CameraAndMeasureSettings extends Mixins(confirmModal) {
  @Prop({ type: Object, default: () => new Section() }) sectionInformation!: Section;
  /*----------  vuex  ----------*/
  private SET_MEASURE_CAMERA_SETTINGS!: Function;
  private isUserAdmin!: boolean;

  actionMessage: ActionMessage | null = null;
  isBusy = false;
  sectionSettings = new measureAndCameraSettings();

  /*----------  Watcxher to set the local section settings  ----------*/
  @Watch("sectionInformation", { immediate: true })
  updateCurrentSectionSettings() {
    this.sectionSettings = this.sectionInformation.isCamera
      ? this.sectionInformation.state.cameraSettings
      : this.sectionInformation.state.measureSettings;
  }

  /*----------  Computed  ----------*/
  get sectionTypeName() {
    return this.sectionInformation.type?.name;
  }

  get mainBoardReasons() {
    return this.sectionInformation.state.stateDetails.deviceStateOne.reasons;
  }

  /*----------  Methodds  ----------*/
  async handleUpdateMeasureAndCamera() {
    this.actionMessage = null;
    this.$emit("updateCamera", null);
    this.confirm(
      this.$t("project.section.informationModal.confirmModals.msgSetMeasureCameraSettings").toString(),
      async () => {
        this.isBusy = true;
        const sectionId = this.sectionInformation.id;
        const sectionTypeApi = this.sectionInformation.type?.name;
        const measureCameraSectionSettings = this.sectionSettings;
        const res = await this.SET_MEASURE_CAMERA_SETTINGS({
          sectionId,
          sectionTypeApi,
          measureCameraSectionSettings
        });
        if (res.status === 200) {
          if (this.sectionInformation.isCamera) {
            this.$emit("updateCamera", {
              class: "success",
              msg: this.$t(
                "project.section.informationModal.confirmModals.msgSetMeasureCameraSettingsSuccess"
              ).toString()
            });
          } else {
            this.actionMessage = {
              class: "success",
              msg: this.$t(
                "project.section.informationModal.confirmModals.msgSetMeasureCameraSettingsSuccess"
              ).toString()
            };
          }
        } else {
          this.actionMessage = {
            class: "danger",
            msg: res
          };
        }
        this.isBusy = false;
      }
    );
  }

  changeRollAngleValue() {
    this.sectionSettings.rollAngle = this.sectionInformation.state.stateDetails.deviceStateOne.rollAngle;
  }

  changePitchAngleValue() {
    this.sectionSettings.pitchAngle = this.sectionInformation.state.stateDetails.deviceStateOne.pitchAngle;
  }

  get checkValidation() {

    var res = false;

    // If Old Measure without sensor roll and pitch
    if(this.sectionInformation.isMeasure && this.sectionInformation.state.stateDetails.deviceStateOne.rollAngleUnit == 'Degree') {

      res = this.checkThresholdValidation();

      if(this.sectionSettings.angleThreshold1 == 0 || this.sectionSettings.angleThreshold2 == 0) {
        res = true;
      }

      if(!this.sectionSettings.angleThreshold1 || !this.sectionSettings.angleThreshold2) {
        res = true;
      }

      if(!this.sectionSettings.rollAngle || !this.sectionSettings.pitchAngle) {
        res = true;
      }
    }

    return res;

  }

  checkThresholdValidation() {
    var res = false;

    // Parse to string because threshold is defined as string but input is type text
    //      -> thats why parse first to string becaus parseFloat only except string
    if(parseFloat( (this.sectionSettings.angleThreshold2).toString() ) <= parseFloat((this.sectionSettings.angleThreshold1).toString()) ) {
      res = true;
    }

    return res;

  }
}
