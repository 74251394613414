







































































































/**
 * Scene settings for the Scene type of projects
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { WEP_MOCK_URL } from "@/shared/keycloak";

@Component({
  name: "SceneSettings",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters("projects", {
      currentSection: "GetCurrentSectionDetails",
      manualOverrideOptions: "GetManualOverrideOptionsFromState",
      currentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      getSectionsDetails: "GetSectionDetail",
      GetManualOverrideSignOption: "GetManualOverrideSingOptions",
      manualOverrideToggle: "SceneManualOverrideToggle"
    })
  }
})
export default class SceneSettings extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  readonly currentSection!: Section;
  readonly currentProject!: Project;

  private getSectionsDetails!: Function;
  private GetManualOverrideSignOption!: Function;
  private manualOverrideToggle!: Function;

  /*----------  Props  ----------*/
  @Prop({ type: Object, default: () => ({}) }) sectionInformation!: Section;
  @Prop({ type: String, default: "48x48" }) displaySize!: string;

  /*----------  Local data  ----------*/
  isLoading = false;
  sceneUpdateStatus: ActionMessage | null = null;

  localManualOverride: any = false;

  /*----------  Vue life cycles  ----------*/
  created() {
    this.GetManualOverrideSignOption(this.sectionInformation.id);
  }

  mounted() {
    this.getSectionsDetails(this.sectionInformation.id).then(() => {
      if (this.currentSection.state.sceneSettings) {
        this.localManualOverride = this.currentSection.state.sceneSettings.manualOverride;
      }
    });
  }

  /*----------  Watch  ----------*/
  @Watch("currentSection", { immediate: true })
  HandleCurrentSectionWatch(val: any) {
    if (val && val.state) {
      if (val.state.sceneSettings && typeof val.state.sceneSettings.filename === "string") {
        let isEmpty = val.state.sceneSettings.filename === "";
        let isTraffic =
          (val.state.sceneSettings.customFilenameEnabled &&
            val.state.sceneSettings.filename.replace(".bmp", "") === val.state.sceneSettings.customFilenameTraffic) ||
          val.state.sceneSettings.filename.replace(".bmp", "") === "Stau";
        let isTrafficRisk =
          (val.state.sceneSettings.customFilenameEnabled &&
            val.state.sceneSettings.filename.replace(".bmp", "") ===
              val.state.sceneSettings.customFilenameTrafficRisk) ||
          val.state.sceneSettings.filename.replace(".bmp", "") === "Stgefahr";
        if (!val.state.sceneSettings.manualOverride) {
          val.state.sceneSettings.filename = {
            name: null,
            value: null
          };
        } else {
          val.state.sceneSettings.filename = {
            name: isTraffic
              ? this.$t("project.section.displayInterval.filenames.Stau")
              : isTrafficRisk
              ? this.$t("project.section.displayInterval.filenames.Stgefahr")
              : isEmpty
              ? this.$t("project.section.displayInterval.filenames.empty")
              : val.state.sceneSettings.filename.replace(/.bmp/g, ""),
            value: val.state.sceneSettings.filename
          };
        }
      }
    }
    this.localManualOverride = this.currentSection.state.sceneSettings?.manualOverride;
  }

  /*----------  Methods   ----------*/
  async resetDisplayLocalState() {
    const sectionDetails = await this.getSectionsDetails(this.currentSection.id);
    if (sectionDetails.status === 200) {
      this.isLoading = false;
    }
  }

  // handle radio button click change to automatic mode or show multiselect of signs
  handleManualOverride() {
    this.sceneUpdateStatus = null;
    if (!this.currentSection.state.sceneSettings?.manualOverride)
      this.confirm(
        this.$t("project.section.displayInterval.confirmModals.msgToggleOnOverride").toString(),
        async () => {
          this.isLoading = true;
          let MOSettings = {
            id: this.currentSection.state.sceneSettings?.id,
            manualOverride: this.currentSection.state.sceneSettings?.manualOverride,
            filename: (this as any).currentSection.state.sceneSettings?.filename.value
          };
          const res = await this.manualOverrideToggle({
            sectionId: this.sectionInformation.id,
            MOSettings
          });
          if (res.status === 200) {
            this.resetDisplayLocalState();
            this.sceneUpdateStatus = {
              class: "success",
              msg: this.currentSection.state.sceneSettings?.manualOverride
                ? this.$t("project.section.displayInterval.confirmModals.msgManualSuccessMsg")
                : this.$t("project.section.displayInterval.confirmModals.msgAutoSuccessMsg")
            };
          } else if (res.response.status === 400) {
            this.sceneUpdateStatus = {
              class: "warning",
              msg: (this as any).$t("archiveProjects.actions.action400res")
            };
            this.isLoading = false;
            this.getSectionsDetails(this.sectionInformation.id);
          } else {
            this.isLoading = false;
            this.sceneUpdateStatus = {
              class: "danger",
              msg: res.response.data.message
            };
          }
        },
        () => {
          this.isLoading = false;
          this.resetDisplayLocalState();
          return;
        }
      );
    else this.localManualOverride = true;
  }

  HandleAddCustomImage(customFilename: string) {
    const filename = customFilename.replace(/.bmp/g, "").replace(/.gif/g, "");
    this.sceneUpdateStatus = null;
    this.closeSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgSetSign", {
        sign: filename
      }).toString(),
      async () => {
        this.isLoading = true;
        const manualOverrideSettings = {
          id: this.currentSection.state.sceneSettings?.id,
          manualOverride: true,
          filename: `${filename}.bmp`
        };

        const updateSignScene = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (updateSignScene.status === 200) {
          this.sceneUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgSetSignSuccess", {
              sign: filename
            })
          };
          this.resetDisplayLocalState();
        } else {
          this.resetDisplayLocalState();
          this.isLoading = false;
          this.sceneUpdateStatus = {
            class: "danger",
            msg: updateSignScene
          };
        }
      },
      async () => {
        await this.resetDisplayLocalState();
      }
    );
  }

  handleSignChange() {
    this.sceneUpdateStatus = null;
    this.closeSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgSetSign", {
        sign: (this as any).currentSection.state.sceneSettings?.filename.name
      }).toString(),
      async () => {
        this.isLoading = true;
        const manualOverrideSettings = {
          id: this.currentSection.state.sceneSettings?.id,
          manualOverride: true,
          filename: (this as any).currentSection.state.sceneSettings?.filename.value
        };

        const updateSignScene = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (updateSignScene.status === 200) {
          this.sceneUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgSetSignSuccess", {
              sign: (this as any).currentSection.state.sceneSettings?.filename.name
            })
          };
          this.resetDisplayLocalState();
        } else {
          this.resetDisplayLocalState();
          this.isLoading = false;
          this.sceneUpdateStatus = {
            class: "danger",
            msg: updateSignScene
          };
        }
      },
      async () => {
        await this.resetDisplayLocalState();
      }
    );
  }

  // handle div events of changing modes
  async handleManualToggle(manual: boolean) {
    if (this.currentProject.isArchived) return;
    this.sceneUpdateStatus = null;
    if (!manual)
      this.confirm(
        this.$t("project.section.displayInterval.confirmModals.msgToggleOnOverride", {
          overrideStatus: manual
        }).toString(),
        async () => {
          this.isLoading = true;
          let MOSettings = {
            id: this.currentSection.state.sceneSettings?.id,
            manualOverride: manual,
            filename: (this as any).currentSection.state.sceneSettings?.filename.value
          };

          const toggleSceneManualOverride = await this.manualOverrideToggle({
            sectionId: this.sectionInformation.id,
            MOSettings
          });

          if (toggleSceneManualOverride.status === 200) {
            this.resetDisplayLocalState();
            this.sceneUpdateStatus = {
              class: "success",
              msg: manual
                ? this.$t("project.section.displayInterval.confirmModals.msgManualSuccessMsg")
                : this.$t("project.section.displayInterval.confirmModals.msgAutoSuccessMsg")
            };
          } else {
            this.isLoading = false;
            this.sceneUpdateStatus = {
              class: "danger",
              msg: toggleSceneManualOverride
            };
          }
        },
        () => {
          this.resetDisplayLocalState();
          return;
        }
      );
    else this.localManualOverride = true;
  }
  closeSignMultiSelect() {
    // close multiselect after select a sign
    let element: HTMLElement | any = document.getElementById("selectedSign") as HTMLElement | any;
    element.focus();
    element.blur();
  }
}
